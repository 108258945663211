import axios from 'axios';

let prefix = "/shop"

const ShopApi = {
	
	listShop(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	listShopRef(payload){
		return axios.get( prefix + "/list-ref", {params: payload})
	},

	listPaymentTypeRef(payload){
		return axios.get( prefix + "/list-payment-type-ref", {params: payload})
	},

	createShop(payload){
		return axios.post( prefix + "/create", payload)
	},

	updateShop(payload){
		return axios.post( prefix + "/update", payload)
	},

	archiveShop(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},

	uploadShopFile(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},

}

export default ShopApi;