import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import( '@/views/_general/Comingsoon')
const Error404 = () => import( '@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import( '@/views/_general/Login')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import( '@/views/_auth/AuthProfile')
const BroadcastList = () => import('@/views/Broadcasts/BroadcastList')

const Home = () => import( '@/views/_general/Home')

const UserList = () => import( '@/views/Users/UserList')
const UserProfile = () => import( '@/views/Users/UserProfile')

const UserCountryList = () => import( '@/views/Users/UserCountryList')
const UserCountryProfile = () => import( '@/views/Users/UserCountryProfile')

// Puzzle level
const PuzzleLevelList = () => import( '@/views/PuzzleLevels/PuzzleLevelList')
const PuzzleLevelProfile = () => import( '@/views/PuzzleLevels/PuzzleLevelProfile')

const UserTokenList = () => import( '@/views/UserTokens/UserTokenList')

// token type
const TokenTypeList = () => import( '@/views/TokenTypes/TokenTypeList')

const SupportiveSingle = () => import( '@/views/Supports/SupportiveSingle')

const AuditTrailList = () => import( '@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import( '@/views/AuditTrails/AuditTrailProfile')

const ReportList = () => import( '@/views/Reports/ReportList')

const AnnouncementList = () => import( '@/views/Announcements/AnnouncementList')

const SystemVariableList = () => import( '@/views/SystemVariables/SystemVariableList')

const PetHabitatList = () => import( '@/views/PetHabitats/PetHabitatList')
const PetTypeList = () => import( '@/views/PetTypes/PetTypeList')
const PetSpeciesList = () => import( '@/views/PetSpecies/PetSpeciesList')
const PetList = () => import( '@/views/Pets/PetList')
const PetProfile = () => import( '@/views/Pets/PetProfile')
const PetBackgroundList = () => import( '@/views/PetBackgrounds/PetBackgroundList')

const ShopList = () => import( '@/views/Shops/ShopList')

const PromoCodeList = () => import( '@/views/PromoCodes/PromoCodeList')
const PromoCodeProfile = () => import( '@/views/PromoCodes/PromoCodeProfile')

const PaymentRecordList = () => import( '@/views/PaymentRecords/PaymentRecordList')
const PaymentRecordCountryList = () => import( '@/views/PaymentRecords/PaymentRecordCountryList')

const SkillList = () => import( '@/views/Skills/SkillList')

const ApkDownload = () => import( '@/views/SystemVariables/ApkDownload')
const PreRegistration = () => import( '@/views/SystemVariables/PreRegistration')

const VariableTypeList = () => import( '@/views/VariableTypes/VariableTypeList')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [ 1,5 ], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: true, title: 'Home' } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: true, title: 'User List' } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: true, title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: true, title: 'User Profile' } },

	{ path: '/user/list-country', name: 'user.list-country', component: UserCountryList, meta: { auth: true, title: 'User Country List' } },
	{ path: '/user/country/profile/:countryCode', name: 'user-country.profile', component: UserCountryProfile, meta: { auth: true, title: 'User Country Profile' } },

	// token type
	{ path: '/token-type/list', name: 'token_type.list', component: TokenTypeList, meta: { auth: true, title: 'Token Type List' } },

	// support
	{ path: '/support/terms-of-use', name: 'support.terms-of-use', component: SupportiveSingle, meta: { auth: true, title: 'T&C', params: { type: 'terms-of-use' } } },
	{ path: '/support/privacy-policy', name: 'support.privacy-policy', component: SupportiveSingle, meta: { auth: true, title: 'Privacy Policy', params: { type: 'privacy-policy' } } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: true, title: 'Audit Trail List' } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile', component: AuditTrailProfile, meta: { auth: true, title: 'Audit Trail Profile' } },
	
	{ path: '/report', name: 'report', component: ReportList, meta: { auth: true, title: 'Report List' } },
	
	{ path: '/user-token/list', name: 'user-token.list', component: UserTokenList, meta: { auth: true, title: 'User Token List' } },
	
	{ path: '/announcement/list', name: 'announcement.list', component: AnnouncementList, meta: { auth: true, title: 'Announcement List' } },
	
	{ path: '/settings/list', name: 'system-variable.list', component: SystemVariableList, meta: { auth: true, title: 'System Variable List' } },

	{ path: '/puzzle-level/list', name: 'puzzle-level.list', component: PuzzleLevelList, meta: { auth: true, title: 'Puzzle Level List' } },
	{ path: '/puzzle-level/profile/:id', name: 'puzzle-level.profile', component: PuzzleLevelProfile, meta: { auth: true, title: 'Puzzle Level Profile' } },
	
	{ path: '/pet-habitat/list', name: 'pet-habitat.list', component: PetHabitatList, meta: { auth: true, title: 'Pet Habitat List' } },
	
	{ path: '/pet-background/list', name: 'pet-background.list', component: PetBackgroundList, meta: { auth: true, title: 'Pet Background List' } },
	
	{ path: '/pet-type/list', name: 'pet-type.list', component: PetTypeList, meta: { auth: true, title: 'Pet Type List' } },
	
	{ path: '/pet-species/list', name: 'pet-species.list', component: PetSpeciesList, meta: { auth: true, title: 'Pet Species List' } },
	
	{ path: '/pet/list', name: 'pet.list', component: PetList, meta: { auth: true, title: 'Pet List' } },
	{ path: '/pet/profile/:id', name: 'pet.profile', component: PetProfile, meta: { auth: true, title: 'Pet Profile' } },
	
	{ path: '/shop/list', name: 'shop.list', component: ShopList, meta: { auth: true, title: 'Shop List' } },
	
	{ path: '/promo-code/list', name: 'promo-code.list', component: PromoCodeList, meta: { auth: true, title: 'Promo Code List' } },
	{ path: '/promo-code/profile/:id', name: 'promo-code.profile', component: PromoCodeProfile, meta: { auth: true, title: 'Promo Code Profile' } },
	
	{ path: '/skill/list', name: 'skill.list', component: SkillList, meta: { auth: true, title: 'Skill List' } },
	
	{ path: '/payment-record/list', name: 'payment-record.list', component: PaymentRecordList, meta: { auth: true, title: 'Payment Record List' } },
	
	{ path: '/payment-record/list-country', name: 'payment-record.list-country', component: PaymentRecordCountryList, meta: { auth: true, title: 'Payment Record Country List' } },

	{ path: '/broadcast/list', name: 'broadcast.list', component: BroadcastList, meta: { auth: true, title: 'Broadcast list' } },
	
	{ path: '/variable-type/list', name: 'variable-type.list', component: VariableTypeList, meta: { auth: true, title: 'Variable Type list' } },

]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },
	{ path: '/download', name: 'download', component: ApkDownload, meta: { auth: null, title: 'Download Apk' } },
	{ path: '/pre-registration', name: 'pre-registration', component: PreRegistration, meta: { auth: null, title: 'Pre Registration' } },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	next()
});

export default router