import axios from 'axios';

let prefix = "/token-type"

const TokenTypeApi = {
	
	getTokenTypeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateTokenType(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default TokenTypeApi;