import axios from 'axios';

let prefix = "/pet-dialogue"

const PetDialogueApi = {
	
	listPetDialogue(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listPetDialogueTypeRef(payload){
		return axios.get( prefix + "/type-ref", {params: payload})
	},
	createPetDialogue(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePetDialogue(payload){
		return axios.post( prefix + "/update", payload)
	},
	deletePetDialogue(payload){
		return axios.delete( prefix + "/remove", {params: payload})
	},

}

export default PetDialogueApi;