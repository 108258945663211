import axios from 'axios';

let prefix = "/pet-skill-detail"

const PetSkillDetailApi = {
	
	listPetSkillDetail(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createPetSkillDetail(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePetSkillDetail(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default PetSkillDetailApi;