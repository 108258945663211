import axios from 'axios';

let prefix = "/pet-type"

const PetTypeApi = {
	
	listPetType(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	createPetType(payload){
		return axios.post( prefix + "/create", payload)
	},

	updatePetType(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default PetTypeApi;