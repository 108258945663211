import axios from 'axios';

let prefix = "/puzzle-level"

const AuthApi = {
	listPuzzleLevel(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listNewUserPuzzle(payload){
		return axios.get( prefix + "/list-new-user-map", {params: payload})
	},
	getPuzzleLevel(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	updatePuzzleLevel(payload){
		return axios.post( prefix + "/update", payload)
	},
	createPuzzleLevel(payload){
		return axios.post( prefix + "/create", payload)
	},
	deletePuzzleLevel(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
}

export default AuthApi;