import axios from 'axios';

let prefix = "/promo-code"

const PromoCodeApi = {
	
	listPromoCode(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	getPromoCode(payload){
		return axios.get( prefix + "/get", {params: payload})
	},

	createPromoCode(payload){
		return axios.post( prefix + "/create", payload)
	},

	updatePromoCode(payload){
		return axios.post( prefix + "/update", payload)
	},

	archivePromoCode(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},

}

export default PromoCodeApi;