import axios from 'axios';

let prefix = "/pet-skill"

const PetSkillApi = {
	
	listPetSkill(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listPetSkillTypeRef(payload){
		return axios.get( prefix + "/type-ref", {params: payload})
	},
	listSkillUseTypeRef(payload){
		return axios.get( prefix + "/skill-use-type-ref", {params: payload})
	},
	listTriggerTypeRef(payload){
		return axios.get( prefix + "/trigger-type-ref", {params: payload})
	},
	createPetSkill(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePetSkill(payload){
		return axios.post( prefix + "/update", payload)
	},
	deletePetSkill(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},

}

export default PetSkillApi;