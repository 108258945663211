import axios from 'axios';

let prefix = "/payment-record"

const PaymentRecordApi = {
	
	listPaymentRecord(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listPaymentRecordCountry(payload){
		return axios.get( prefix + "/list-country", {params: payload})
	},
	exportPaymentRecordCountry(payload){
		return axios.get( prefix + "/export-list-country", {params: payload})
	},
	listPaymentStatusRef(payload){
		return axios.get( prefix + "/status-ref", {params: payload})
	},
}

export default PaymentRecordApi;