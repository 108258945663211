import axios from 'axios';

let prefix = "/pet-species"

const PetSpeciesApi = {
	
	listPetSpecies(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	createPetSpecies(payload){
		return axios.post( prefix + "/create", payload)
	},

	updatePetSpecies(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default PetSpeciesApi;