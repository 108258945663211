import axios from 'axios';

let prefix = "/google-authenticator"

const GoogleAuthenticatorApi = {
	generateGoogleAuthenticator(payload){
		return axios.get( prefix + "/generate", {params: payload})
	},

	activateGoogleAuthenticator(payload){
		return axios.post( prefix + "/activate", payload)
	},


	deactivateGoogleAuthenticator(payload){
		return axios.post( prefix + "/deactivate", payload)
	},

}

export default GoogleAuthenticatorApi;