import axios from 'axios';

let prefix = "/pet-background"

const PetBackgroundApi = {
	
	listPetBackground(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	createPetBackground(payload){
		return axios.post( prefix + "/create", payload)
	},

	updatePetBackground(payload){
		return axios.post( prefix + "/update", payload)
	},

	uploadPetBackgroundFile(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},

	uploadPetBackgroundAssetBundle(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload-asset-bundle", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},

}

export default PetBackgroundApi;