import axios from 'axios';

let prefix = "/announcement"

const AnnouncementApi = {
	
	getAnnouncementList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archiveAnnouncement(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createAnnouncement(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateAnnouncement(payload){
		return axios.post( prefix + "/update", payload)
	},
	setAnnouncementImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearAnnouncementImage(payload){
		return axios.delete( prefix + "/delete-banner", {params: payload})
	},
}

export default AnnouncementApi;