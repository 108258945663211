import axios from 'axios';

let prefix = "/skill"

const SkillApi = {
	listSkill(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	updateSkill(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default SkillApi;