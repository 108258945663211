import axios from 'axios';

let prefix = "/audit-trail"

const AuditTrailApi = {
	listAuditTrail(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getAuditTrailInfo(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getAuditTrailRef(payload){
		return axios.get( prefix + "/ref", {params: payload})
	},
}

export default AuditTrailApi;