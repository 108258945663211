import axios from 'axios';

let prefix = "/advertisementBanner"

const AdvertisementApi = {
	
	getAdvertisementList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archiveAdvertisement(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createAdvertisement(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateAdvertisement(payload){
		return axios.post( prefix + "/update", payload)
	},
	setAdvertisementImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearAdvertisementImage(payload){
		return axios.delete( prefix + "/delete-banner", {params: payload})
	},
	getActionTypes(payload){
		return axios.get( prefix + "/get-action-types", {params: payload})
	},
}

export default AdvertisementApi;