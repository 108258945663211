import axios from 'axios';

let prefix = "/pet-habitat"

const PetHabitatApi = {
	
	listPetHabitat(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	createPetHabitat(payload){
		return axios.post( prefix + "/create", payload)
	},

	updatePetHabitat(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default PetHabitatApi;