import axios from 'axios';

let prefix = "/user-promo-code-history"

const UserPromoCodeHistoryApi = {
	
	listPromoCodeHistory(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default UserPromoCodeHistoryApi;