import axios from 'axios';

let prefix = "/variable-type"

const VariableTypeApi = {
	
	listVariableType(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listVariableTypeRef(payload){
		return axios.get( prefix + "/type-ref", {params: payload})
	},
	createVariableType(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateVariableType(payload){
		return axios.post( prefix + "/update", payload)
	},
	deleteVariableType(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},

}

export default VariableTypeApi;