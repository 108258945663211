import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import api from './api/index.js'
import './bootstrap'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import VueRouter from 'vue-router'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from './auth'
import VueAuth from '@websanova/vue-auth'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import '@mdi/font/css/materialdesignicons.min.css';
import '@/styles/bw_lib.scss'
import '@/styles/transition_animation.scss'
import '@/styles/vuetify.scss'
import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

window.Vue = Vue // Set Vue globally

Vue.router = router

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL + "/api/"


Vue.prototype.$api = api
dayjs.extend(utc)
Vue.prototype.$dayjs = dayjs

Vue.config.productionTip = false

new Vue({
	vuetify,
	router,
	render: h => h(App)
}).$mount('#app')

//Tiptap
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {
	vuetify: vuetify,
	iconsGroup: 'mdi'
})

//GMAP
import * as GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
	load: {
		key: 'AIzaSyDE4wE_BiZfYbFrD01Q3IUVqkbY3Ru4hdE',
		libraries: 'places',
	},
	installComponents: true
})

// vuetify snackbar
Vue.use(VueToast, {
	duration: 5000,
	dismissible : true,
});

//vue-konva
import VueKonva from 'vue-konva';

Vue.use(VueKonva);

//VUetify DIalog
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
Vue.use(VuetifyDialog, {
	context: {
		vuetify
	},
	confirm: {
		icon: "mdi-alert",
		actions: {
			false: 'No',
			true: {
				text: 'Yes',
				color: 'error'
			}
		}
	}
})

import VueRx from 'vue-rx'

Vue.use(VueRx)