import axios from 'axios';

let prefix = "/broadcast"

const BroadcastApi = {
	
	getBroadcastList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createBroadcastMessage(payload){
		return axios.post( prefix + "/create", payload)
	},
	archiveBroadcastMessage(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},

}

export default BroadcastApi;