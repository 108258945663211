import axios from 'axios';

let prefix = "/supportive"

const SupportiveApi = {
	
	getSupportive(type, payload = null){
		return axios.get( prefix + "/get/" + type, {params: payload})
	},

	updateSupportive(payload){
		return axios.post( prefix + "/update", payload)
	},


}

export default SupportiveApi;