import axios from 'axios';

let prefix = "/auth"

const AuthApi = {
	resetPassword(payload){
		return axios.post( prefix + "/verify/reset-password", payload)
	},
	requestOtp(payload){
		return axios.post( prefix + "/request-otp", payload)
	},
}

export default AuthApi;