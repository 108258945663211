import axios from 'axios';

let prefix = "/contactUs"

const ContactUsApi = {
	
	submitContactUs(payload){
		return axios.post( prefix + "/submit", payload)
	},
}

export default ContactUsApi;