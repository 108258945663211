import axios from 'axios';

let prefix = "/referral-code"

const ReferralCodeApi = {
	listReferralByUserId(payload){
		return axios.get( prefix + "/user/list", {params: payload})
	},
}

export default ReferralCodeApi;