import axios from 'axios';

let prefix = "/locale"

const LocaleApi = {
	
	getLocaleSelection(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

}

export default LocaleApi;