import axios from 'axios';

let prefix = "/daily-puzzle"

const DailyPuzzleApi = {
	changeDailyPuzzle(payload){
		return axios.post( prefix + "/change-daily-puzzle", payload)
	},
}

export default DailyPuzzleApi;